import React from "react"
import { graphql} from "gatsby"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { PillButton } from "../UI/PillButton"
import SectionHeading from "../UI/SectionHeading"
export const fragment = graphql`
  fragment leftImageWithTextAndBtnFragment on WpPage_Flexlayouts_FlexibleLayouts_LeftImageWithTextAndBtn {
    sectionHeading
    text
    headingType
    image {
      ...GatsbyImageQuery
    }
    button {
      url
      title
    }
  }
`

const LeftImageWithTextAndBtn = ({
  sectionHeading,
  text,
  image,
  button,
  headingType
}) => {
  const img = getImage(image?.localFile)

  return (
    <div className={`bg-pale-grey`}>
      <Container>
        <Row className="align-items-center bg-primary pb-5 py-lg-6 px-xl-6">
          <Col
            sm={12}
            md={6}
            lg={4}
            className="mt-5 mt-lg-0 d-flex justify-content-center">
            <GatsbyImage image={img} alt={img?.altText} />
          </Col>
          <Col sm={12} md={6} lg={8} className="mx-auto">
            <div className={`d-flex flex-column`}>                            
              <SectionHeading 
                text={sectionHeading} 
                type={headingType} 
                className="text-white py-4 pt-lg-0 pb-lg-2 text-center text-lg-start"
               />              
              <div
                dangerouslySetInnerHTML={{ __html: text }}
                className="px-3 px-sm-0 pb-5 pb-lg-3 mediumText text-white text-center text-lg-start"
              />
              {button && (
                <PillButton
                  link={button.url}
                  title={button.title}
                  classes="px-4"
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default LeftImageWithTextAndBtn